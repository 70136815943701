<template>
  <el-dialog
    v-model='visible'
    :title='title'
    width='70%'
    center
    :destroy-on-close='true'
    top='6vh'
    style='padding-top: 10px; position: relative'
  >
    <template #default>
      <el-scrollbar height='580px'>
        <div v-if='currentItem.source'>
          <div class='publicSource'>
            来源：{{ currentItem.source }}
            <text class='publicTime'>发布时间：{{ currentItem.date }}</text>
          </div>
        </div>
        <div class='publicContent' v-html='content'></div>
      </el-scrollbar>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'NesiArticleDetailModal',
  props: {},
  data() {
    return {
      currentItem: {},
      visible: false,
      title: ''

    }
  },
  computed: {
    content() {
      const item = this.currentItem
      if (!item.content) return ''
      let tempContent = item.content
      this.title = item.title
      tempContent = tempContent.replace(
        /src\s*=\s*&quot;\s*\//g,
        'style=&quot;&quot; src=&quot;' + 'https://www.nnfwzl.com' + '/'
      )
      tempContent = tempContent.replace(
        /href\s*=\s*&quot;\s*\//g,
        'style=&quot;width: 97%;&quot; href=&quot;' + 'https://www.nnfwzl.com' + '/'
      )
      tempContent = tempContent.replace(/&gt;/g, '>')
      tempContent = tempContent.replace(/&lt;/g, '<')
      tempContent = tempContent.replace(/&quot;/g, '"')
      tempContent = tempContent.replace(/&amp;nbsp;/g, ' ')
      tempContent = tempContent.replace(
        /<table\s*width\s*=\s*"[0-9]*"\s*style\s*="width:\s*[0-9]*px;">/g,
        '<table width="852" style="width: 100%;">'
      )
      return tempContent
    }
  },
  methods: {
    open(item) {
      this.currentItem = item
      this.visible = true
    }
  }
}
</script>

<style scoped>
.publicTitle {
  text-align: center;
  padding: 15px 0;
  font-size: 20px;
}

.publicSource {
  padding: 15px 20px;
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: space-between;
}

.publicContent {
  padding: 0 20px;
  font-size: 16px;
}
</style>
