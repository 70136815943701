<template>
  <div class="home-wrapper">
    <div class="carousel-wrapper">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
          <el-image
            style="width: 100%; height: 300px"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-banner-1.jpg"
            :fit="`fit`"
          />
        </el-carousel-item>
        <el-carousel-item>
          <el-image
            style="width: 100%; height: 300px"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-banner-2.jpg"
            :fit="`fit`"
          />
        </el-carousel-item>
        <el-carousel-item>
          <el-image
            style="width: 100%; height: 300px"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-banner-3.jpg"
            :fit="`fit`"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 快捷访问 -->
    <div class="quick-wrapper">
      <div class="quick-content">
        <div class="quick-item house" @click="goPage('/user/house/query')">
          <!-- <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/house.png"
          /> -->
          <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/house-up-new.png"
          />
          <div class="quick-title">我要发房</div>
          <div class="quick-tip">便捷发房，快速成交</div>
        </div>
        <div class="quick-item map" @click="goPage('/mapSearch')">
          <!-- <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/map.png"
          /> -->
          <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/house-map-new.png"
          />
          <div class="quick-title">地图找房</div>
          <div class="quick-tip">精确定位，一览无余</div>
        </div>
        <div class="quick-item search" @click="goPage('/house/list')">
          <!-- <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/search.png"
          /> -->
          <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/house-search-new.png"
          />
          <div class="quick-title">条件搜索</div>
          <div class="quick-tip">便捷找房，一键搜索</div>
        </div>
        <div class="quick-item info" @click="goPage('/info')">
          <!-- <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/info.png"
          /> -->
          <el-image
            class="image"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/house-verify-new.png"
          />
          <div class="quick-title">行业动态</div>
          <div class="quick-tip">行业动态，随时查看</div>
        </div>
      </div>
    </div>
    <!-- 预告房源 -->
    <div class="prev-house-wrapper">
      <div class="prev-house-content">
        <el-image
          class="image"
          src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-info-ad-fc.png"
        />
        <div class="title-wrapper">
          <div class="title">预告房源</div>
          <div class="more" @click="goPage('/house/list?tab=sixth')">
            更多预告房源
            <el-image
              class="image"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
            />
          </div>
        </div>
        <div class="house-list-wrapper">
          <nesi-apartment-item
            v-for="item in itemList"
            :key="item.id"
            :item="item"
            :minio="minioUrl"
            types="1"
            @click="toApartmentDetail(item, 1)"
          ></nesi-apartment-item>
        </div>
      </div>
    </div>

    <!-- 预告房源 -->
    <div class="prev-house-wrapper">
      <div class="prev-house-content">
        <el-image
          class="image"
          src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-info-ad-fc.png"
        />
        <div class="title-wrapper">
          <div class="title">集中式公寓</div>
          <div class="more" @click="goPage('/house/list?tab=fifth')">
            更多公寓项目
            <el-image
              class="image"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
            />
          </div>
        </div>
        <div class="house-list-wrapper">
          <nesi-apartment-item
            v-for="item in itemListApartment"
            :key="item.id"
            :item="item"
            :minio="minioUrl"
            types="2"
            @click="toApartmentDetail(item)"
          ></nesi-apartment-item>
        </div>
      </div>
    </div>

    <!-- 二手房-应用系统 -->
    <div class="prev-house-wrapper bg-gray">
      <div class="prev-house-content">
        <el-image
          style="cursor: pointer"
          class="image third-image"
          v-if="$instance.refs.homeFloatRef"
          @click="$instance.refs.homeFloatRef.goSecondHead"
          src="https://pc.nnfwzl.com/rent-house/rent-platform-web/second-head-1.jpg"
        />
        <div class="title-wrapper">
          <div class="title">应用系统</div>
          <div class="more">
            <el-image
              class="image"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
            />
          </div>
        </div>
        <div class="sys-wrapper" v-if="$instance.refs.homeFloatRef">
          <div
            class="sys-item"
            @click="$instance.refs.homeFloatRef.openLink('rewardFun')"
          >
            财政专项补助资金申报入口
          </div>
          <div
            class="sys-item"
            @click="$instance.refs.homeFloatRef.openLink('fundSupervisionFun')"
          >
            租赁资金监管入口
          </div>
          <div
            class="sys-item"
            @click="$instance.refs.homeFloatRef.goSecondHead"
          >
            南宁市住房服务监管平台
          </div>
          <div
            class="sys-item"
            @click="$instance.refs.homeFloatRef.openLink('bzfFun')"
          >
            南宁市保租房系统
          </div>
        </div>
      </div>
    </div>
    <!-- 最新房源 -->
    <div class="prev-house-wrapper">
      <div class="prev-house-content">
        <el-image
          class="image"
          src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-info-ad-fc.png"
        />
        <div class="title-wrapper">
          <div class="title">最新房源</div>
          <div class="more" @click="goPage('/house/list')">
            更多最新房源
            <el-image
              class="image"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
            />
          </div>
        </div>
        <div class="new-house-wrapper">
          <div
            class="house-item"
            v-for="item in houseList"
            :key="item.id"
            @click="showHouseDetails(item)"
          >
            <el-image class="image" :src="item.image">
              <template #error>
                <div class="image-slot">
                  <div
                    style="
                      width: 100%;
                      height: 200px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      opacity: 0.8;
                      background: #ecf5ff;
                      flex-direction: column;
                      color: #5298f5;
                      font-size: 14px;
                    "
                  >
                    <el-image
                      class="image"
                      style="width: 140px; height: 100px"
                      src="https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png"
                    ></el-image>
                    图片正在拍摄中
                  </div>
                </div>
              </template>
            </el-image>
            <el-image
              v-if="item.thirdSource"
              style="position: absolute; right: 0; top: 0"
              :src="item.thirdSource"
              referrer="no-referrer|origin|unsafe-url"
            >
            </el-image>
            <div class="validate">
              {{
                item.govVerification && item.govVerification !== '0'
                  ? '产权核验'
                  : '未核验'
              }}
            </div>
            <div class="house-item-field">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="field">
                {{ `${item.street} | ${item.buildingArea}㎡ | ${item.upAt}` }}
              </div>
              <div class="field">统一编码：{{ item.no }}</div>
              <div class="price">
                {{ item.rentPrice }}
                <div class="unit">元/月</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 最新房源 -->
    <div class="prev-house-wrapper bg-gray">
      <div class="prev-house-content">
        <el-image
          class="image"
          src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-info-ad-zf.png"
        />
        <div class="title-wrapper">
          <div class="title">求租信息</div>
          <div class="more" @click="goToRent">
            更多求租信息
            <el-image
              class="image"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
            />
          </div>
        </div>
        <div class="rent-info-wrapper">
          <div class="rent-info-item">
            <div class="item" v-for="item in rentList" :key="item.id">
              <div class="house">
                求
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleRentInfo"
                  >{{ item.title }}
                </el-link>
                的房源
              </div>
              <div class="price">
                租金范围：<span style="color: #fd1e26">{{ item.price }}</span>
              </div>
              <div class="link">
                <span class="name">{{ item.name }}</span
                >：
                <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="callPhone(item)"
                  >拨打号码
                </el-button>
              </div>
              <div>入住时间：{{ item.iDate }}</div>
              <div>发布时间：{{ item.pDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公示公告 -->
    <div class="prev-house-wrapper">
      <div class="prev-house-content">
        <el-image
          class="image"
          src="https://pc.nnfwzl.com/rent-house/rent-platform-web/new-info-ad.png"
        />
        <div class="zx-info-wrapper">
          <div class="zx-item">
            <div class="title-wrapper rent-title-wrapper">
              <div class="title">公示公告</div>
              <div class="more" @click="goPage('/notice')">
                更多公示公告
                <el-image
                  class="image"
                  src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
                />
              </div>
            </div>
            <div class="rent-info-wrapper" style="min-height: 277px">
              <div
                class="rent-info-item"
                v-for="item in noticeList1"
                :key="item.id"
              >
                <div class="item">
                  <div
                    class="house w-80"
                    @click="showArticleDetail(item)"
                    style="cursor: pointer"
                  >
                    {{ item.title }}
                  </div>
                  <div>{{ item.date }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="zx-item">
            <div class="title-wrapper rent-title-wrapper">
              <div class="title">行业动态</div>
              <div class="more" @click="goPage('/info')">
                更多行业动态
                <el-image
                  class="image"
                  src="https://pc.nnfwzl.com/rent-house/rent-platform-web/arrow.png"
                />
              </div>
            </div>
            <div class="rent-info-wrapper">
              <div
                class="rent-info-item"
                v-for="item in noticeList2"
                :key="item.id"
              >
                <div class="item">
                  <div
                    class="house w-80"
                    @click="showArticleDetail(item)"
                    style="cursor: pointer"
                  >
                    {{ item.title }}
                  </div>
                  <div>{{ item.date }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nesi-article-detail-modal ref="articleDetailModalRef" />

    <!-- 红黑榜 -->
    <div class="prev-house-wrapper bg-gray">
      <div class="prev-house-content">
        <div class="title-wrapper" style="background-size: 150% 100%">
          <div class="title">企业信用红黑榜</div>
          <!--TOP30-->
        </div>
        <div style="padding: 5px 0">
          <el-select
            v-model="creditForm.companyType"
            class="m-2"
            placeholder="Select"
            size="large"
            @change="companyTypeChange"
          >
            <el-option label="租赁企业" value="3" />
            <el-option label="经纪机构" value="2" />
          </el-select>
          <el-select
            v-model="creditForm.creditType"
            class="m-2"
            placeholder="Select"
            size="large"
            style="margin-left: 10px"
            @change="creditTypeChange"
          >
            <el-option label="红榜TOP" value="1" />
            <el-option label="黑榜TOP" value="2" />
          </el-select>
        </div>
        <div class="rent-info-wrapper" style="padding: 10px">
          <el-table
            :data="creditData"
            style="width: 100%"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            :border="true"
          >
            <el-table-column label="排名" type="index" width="80">
              <template #default="{ row }">
                {{ row.bdpm }}
              </template>
            </el-table-column>
            <el-table-column prop="companyName" label="企业名称" />
            <el-table-column prop="name" label="信用评分" width="350">
              <template #default="scope">
                <el-progress
                  style="width: 320px"
                  :text-inside="true"
                  :stroke-width="16"
                  :percentage="Number(scope.row.number)"
                  :format="perFormat"
                />
              </template>
            </el-table-column>

            <el-table-column prop="name" :label="creditTableLabel" width="350">
              <template #default="scope">
                <el-progress
                  v-if="scope.row.lastNumber"
                  style="width: 320px"
                  :text-inside="true"
                  :stroke-width="16"
                  :percentage="Number(scope.row.lastNumber)"
                  :format="perFormat"
                />
                <span v-else>暂无评分</span>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <p style="font-size: 14px; color: orange">
              <span style="color: orange">温馨提醒</span
              >：按分数从高到底进行排名，如分数相同，则排序相同，企业排名不分先后
            </p>
            <el-pagination
              style="text-align: right; margin-top: 10px"
              background
              layout="total, prev, pager, next"
              :total="creditDataTotal"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
    <!--    <left-float :fTop='200' :fLeft='20'></left-float>-->
    <home-float v-show="false" ref="homeFloatRef"></home-float>
  </div>
</template>

<script setup>
import {
  searchItem,
  newHouseList,
  creditList,
  getNotice,
  searchRentInfo,
  getVirtualPhoneApi
} from '@/api/home'
import { ref, onMounted, reactive, getCurrentInstance, nextTick } from 'vue'
import { numberFormat, getSource } from '@/utils/util'
import router from '../../router'
import NesiApartmentItem from '@/components/NesiApartmentItem'
import dayjs from 'dayjs'
import { ElMessage, ElMessageBox } from 'element-plus'
import HomeFloat from '@/components/HomeFloat'
import NesiArticleDetailModal from '@/components/NesiArticleDetailModal'

const $instance = getCurrentInstance()
// const { openLink, goSecondHead } = $instance.refs.homeFloatRef
const homeFloatRef = ref(null)
const minioUrl = ref('')
const articleDetailModalRef = ref()
// 预告房源
const itemList = ref([])
const itemListApartment = ref([])

const getItem = async () => {
  /* 预告房源 */
  const resp = await searchItem({ type: '1', pageSize: 2 })
  if (!minioUrl.value) {
    minioUrl.value = resp.minioUrl
  }
  itemList.value = resp.records.records

  /* 集中式公寓 */
  const resp2 = await searchItem({ type: '2', pageSize: 2 })
  itemListApartment.value = resp2.records.records
}

// 最新房源
const houseList = ref([])
const getNewHouseList = async () => {
  const resp = await newHouseList({ type: 1, houseType: 3 })
  if (!minioUrl.value) {
    minioUrl.value = resp.minioUrl
  }
  let list = []
  if (resp.records.records.length && resp.records.records.length > 4) {
    list = resp.records.records.slice(0, 4)
  } else {
    list = resp.records.records
  }
  houseList.value = list.map((item) => {
    const rentShow = item.rentType === 2 ? '分租' : '整租' // 出租类型
    let title = `${rentShow}·${item.projectName}`
    if (item.rentType === 2) {
      title = `${rentShow}·${item.projectName}·${item.lesseeRoomName}`
    }

    let path = ''
    if (item.houseOther) {
      const imageItem = JSON.parse(item.houseOther)[0]
      if (imageItem.scanData) path = imageItem.scanData
      else if (imageItem.smjsj) path = imageItem.smjsj
      else path = imageItem.src
    }

    const thirdSource = item.source ? getSource(item.source) : ''
    const imageUrl = item.source ? item.imageUrl : minioUrl.value + path

    return {
      projectName: item.projectName, // 小区名称
      buildingArea: item.buildingArea, // 名称
      rentType: item.rentType, // 出租类型
      upAt: item.upAt.split(' ')[0], // 上架日期
      rentPrice: numberFormat(item.rentPrice, 2, '.', ','), // 租金
      image: imageUrl,
      lesseeRoomName: item.lesseeRoomName, // 出租部位
      lesseeRoomNumber: item.lesseeRoomNumber, // 分租部位编号
      id: item.houseId, // 房源ID
      source: item.source,
      linkAddress: item.linkAddress,
      houseNumber: item.houseNumber, // 房源编号
      title, // 组装title
      street: item.street, // 街道
      thirdSource: thirdSource, // 第三方图片
      govVerification: item.govVerification,
      no: item.areaId + item.houseNumber // 统一编号
    }
  })
}

// 求租信息
const rentList = ref([])
const getRentInfo = async () => {
  const resp = await searchRentInfo({ pageNo: 1, pageSize: 5 })
  rentList.value = resp.records.map((item) => {
    const rentTypeCn =
      item.rentType === 0 ? '不限' : item.rentType === 2 ? '分租' : '整租'
    const bean = {
      title: `${item.locationName ? item.locationName : '不限区'} | ${
        item.communityName
      } | ${item.roomNum}室${item.hallNum}厅${
        item.toiletNum
      }卫 | ${rentTypeCn} | ${
        item.situationName ? item.situationName : '其他'
      }`,
      price:
        item.rentPrice && item.rentPrice != '0'
          ? item.rentPrice.split('_').join('-') + '元/月'
          : '不限',
      name: item.propertyRightName,
      iDate: item.upAt.split(' ')[0],
      pDate: item.updatedAt.split(' ')[0],
      id: item.id,
      userId: item.userId
    }
    if (item.rentPrice === '0_800') {
      bean.price = '800元以内/月'
    }
    if (item.rentPrice === '5000_') {
      bean.price = '5000元以上/月'
    }
    return bean
  })
}

// 公示公告
const noticeList1 = ref([])
const noticeList2 = ref([])
const getNotices = async (data) => {
  const resp = await getNotice(data)
  let list = []
  if (resp.records.length > 5) {
    list = resp.records.slice(0, 5)
  } else {
    list = resp.records
  }
  const tempList = list.map((item) => {
    return {
      title: item.title,
      id: item.id,
      content: item.content,
      source: item.source,
      date: item.publishedAt
        ? dayjs(item.publishedAt).format('YYYY-MM-DD')
        : '--'
    }
  })
  if (data.category === '1') {
    noticeList1.value = tempList
  } else {
    noticeList2.value = tempList
  }
}
// 查看文章
const showArticleDetail = (item) => {
  const routeUrl = router.resolve({
    path: '/article',
    query: {
      id: item.id
    }
  })
  window.open(routeUrl.href, '_blank')
  // articleDetailModalRef.value.open(item)
}

// 红黑榜
const creditForm = reactive({
  companyType: '3',
  creditType: '1'
})
const creditTableLabel = ref('本轮')
const creditDataAll = ref([])
const creditDataTotal = ref(0) // 总记录数
const creditData = ref([])
const currentPage = ref(1) // 默认页码为 1
const pagesize = ref(10) // 默认一页显示10条

const getCreditList = async (data) => {
  data.pageNo = currentPage.value
  data.pageSize = pagesize.value

  const { records = [], total = 0, countId = '本轮' } = await creditList(data)
  creditData.value = records.map((item) => {
    return {
      companyName: item.qymc,
      number: item.xypf,
      lastNumber: item.lastXypf,
      bdpm: item.bdpm
    }
  })
  creditTableLabel.value = countId
  creditDataTotal.value = total
}
const perFormat = (percentage) => {
  return percentage + '分'
}
const toApartmentDetail = (item, apartmentType = 2) => {
  const routeUrl = router.resolve({
    name: 'apartmentDetail',
    query: { id: item.id, apartmentType }
  })
  window.open(routeUrl.href, '_blank')
}

const handleCurrentChange = (page) => {
  currentPage.value = page
  getCreditList(creditForm)
}

const companyTypeChange = (value) => {
  currentPage.value = 1
  creditForm.companyType = value
  getCreditList(creditForm)
}

const creditTypeChange = (value) => {
  currentPage.value = 1
  creditForm.creditType = value
  getCreditList(creditForm)
}
// 拨打虚拟电话
const callPhone = async (item) => {
  const result = await getVirtualPhoneApi(item.userId)
  ElMessageBox.alert(`请手机拨打联系人号码：${result}`, '拨打提示', {
    confirmButtonText: '确认',
    callback: (action) => {}
  })
  // const { errorCode, telX, errorMsg } = result
  /* if (errorCode === 0 && telX) {

  } else {
    ElMessage.error(errorMsg)
  } */
}
// 查看房源详情
const showHouseDetails = (item) => {
  if (item.source) {
    window.open(item.linkAddress, '_blank')
  } else {
    const routeUrl = router.resolve({
      name: 'houseDetail',
      query: { id: item.id }
    })
    window.open(routeUrl.href, '_blank')
  }
}

onMounted(() => {
  getItem()
  getNewHouseList()
  getCreditList(creditForm)
  // 公告公示
  getNotices({ category: '1', pageNo: 1, pageSize: 5 })
  // 行业动态
  getNotices({ category: '2', pageNo: 1, pageSize: 5 })
  getRentInfo()
})

const goToRent = () => {
  router.push('/rent')
}

const handleRentInfo = () => {
  console.log('hello')
}
// 页面跳转
const goPage = (url) => {
  if (url === '/mapSearch') {
    const routeUrl = router.resolve({
      path: url
    })
    window.open(routeUrl.href, '_blank')
  } else {
    router.push(url)
  }
}
</script>

<style lang="scss" scoped>
.home-wrapper {
  .carousel-wrapper {
    width: 100vw;
  }

  .quick-wrapper {
    height: 250px;
    width: 100%;
    background-image: url('https://pc.nnfwzl.com/rent-house/rent-platform-web/crossword_bg.jpg');

    .quick-content {
      width: 1280px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .quick-item {
        box-shadow: 0 0 20px 20px #e4e7ed;
        background-color: #ffffff;
        width: 250px;
        padding: 10px 0;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all 0.3s linear;

        &.house {
          // background: #5298f5;
          background: #ffffff;
        }

        &.map {
          // background: #29cf84;
          background: #ffffff;
        }

        &.search {
          // background: #fd887c;
          background: #ffffff;
        }

        &.info {
          // background: #a471fc;
          background: #ffffff;
        }

        .image {
          width: 70px;
          height: 70px;
          // border-radius: 50%;
        }

        .quick-title {
          padding: 10px 0;
          font-weight: 800;
          color: #333333;
        }

        .quick-tip {
          padding: 0 0 10px;
          font-size: 14px;
          color: #bbbbbb;
        }

        // &:hover {
        //   box-shadow: 0 0 10px 10px #e4e7ed;
        //   background-color: #ffffff;
        // }
      }
    }
  }

  .prev-house-wrapper {
    background-color: #ffffff;

    &.bg-gray {
      background-color: #f8fafc;
    }

    width: 100%;

    .prev-house-content {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      padding-bottom: 10px;

      .image {
        width: 100%;
        height: 60px;

        &.third-image {
          height: 120px;
        }
      }

      .title-wrapper {
        background-image: url('https://pc.nnfwzl.com/rent-house/rent-platform-web/title.png');
        background-size: 100% 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-repeat: no-repeat;
        padding: 0 10px 0 10px;

        &.rent-title-wrapper {
          background-size: 150% 100%;
          height: 40px;
          line-height: 40px;
        }

        .title {
          font-size: 18px;
          line-height: 30px;
          color: #ffffff;
          height: 30px;
          font-weight: 600;
        }

        .more {
          font-size: 14px;
          color: #036eb7;
          font-weight: 800;
          height: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .image {
            margin-left: 10px;
            margin-top: 0;
            width: 40px;
            height: 10px;
          }
        }
      }

      .house-list-wrapper {
        .house-item {
          position: relative;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          border: solid 1px #eee;
          padding: 0 10px;
          box-sizing: border-box;
          background: #fff;
          border-radius: 5px;
          cursor: pointer;

          .image {
            width: 300px;
            height: 200px;
          }

          .validate {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #fff;
            font-size: 14px;
            background: #71d5a1;
            padding: 5px 8px;
            font-size: 13px;
            border: 1px solid #71d5a1;
          }

          .house-item-field {
            position: relative;
            margin-left: 10px;
            flex: 1;

            .field {
              height: 30px;
              line-height: 30px;
              font-size: 14px;
              color: #666;

              &.field-auto {
                height: auto;
                line-height: auto;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
              }
            }

            .title {
              padding-top: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 40px;
              line-height: 40px;
              color: #000;
              font-weight: 600;
              font-size: 18px;
            }

            .price {
              position: absolute;
              height: 51px;
              line-height: 51px;
              right: 41px;
              top: 30%;
              color: #db4c3f;
              font-size: 24px;
              font-weight: 600;
              display: flex;

              .unit {
                margin-left: 5px;
                font-size: 14px;
              }
            }
          }
        }
      }

      .sys-wrapper {
        display: flex;
        align-items: center;
        border: solid 1px #eee;
        justify-content: space-between;
        background-color: #ffffff;

        .sys-item {
          width: 28%;
          margin: 20px 10px;
          text-align: center;
          cursor: pointer;
          background: #ecf5ff;
          font-size: 16px;
          transition: all 0.2s;
          height: 70px;
          line-height: 70px;

          &:hover {
            box-shadow: 0 0 20px 5px #e4e7ed;
            color: #409eff;
          }
        }
      }

      .new-house-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px 0;

        .house-item {
          position: relative;
          background-color: #fff;
          box-shadow: 0 8px 20px #ddd;
          width: 300px;
          border-radius: 4px;
          border: solid 1px #f6f6f6;
          cursor: pointer;
          padding: 10px;

          .image {
            height: 200px;
          }

          .validate {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #fff;
            font-size: 14px;
            background: #71d5a1;
            padding: 5px 8px;
            font-size: 13px;
            border: 1px solid #71d5a1;
          }

          .field {
            height: 25px;
            line-height: 25px;
            font-size: 14px;
            color: #666;
          }

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 35px;
            line-height: 35px;
            color: #000;
            font-size: 18px;
          }

          .price {
            height: 35px;
            line-height: 35px;
            color: #db4c3f;
            font-size: 24px;
            font-weight: 600;
            display: flex;

            .unit {
              margin-left: 5px;
              font-size: 14px;
            }
          }
        }
      }

      .rent-info-wrapper {
        border: solid 1px #eee;
        background-color: #ffffff;
        padding: 0 10px;
        color: #666;

        .rent-info-item {
          display: flex;
          flex-direction: column;

          .item {
            display: flex;
            height: 55px;
            line-height: 55px;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            border-bottom: solid 1px #e5e5e5;

            .house {
              width: 40%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &.w-80 {
                width: 84%;
              }
            }

            .price {
              width: 18%;
            }

            .link {
              width: 11%;
              display: flex;
              align-items: center;

              .name {
                display: inline-block;
                width: 30px;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }

      .zx-info-wrapper {
        border: solid 1px #eee;
        background-color: #ffffff;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .zx-item {
          width: 49%;
        }
      }
    }
  }
}
</style>
